:root {
    --light-shade: #f5f5eb;
    --light-accent: #baa1aa;
    --brand-color: #ec959d;
    --dark-shade: #c83951;
    --dark-accent: #45334b;
}

* {
    padding: 0;
    margin: 0;
    font-family: "Quicksand", sans-serif;
    box-sizing: border-box;
}

body {
    background-color: var(--light-shade);
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.LogoAndUser {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.LogoAndUser .user-details {
    display: flex;
    align-items: center;
    min-height: 50px;
    margin-right: 10px;
}
.LogoAndUser .user-details a {
    color: var(--dark-accent);
    font-weight: bold;
    text-decoration: none;
}
.LogoAndUser .user-details a.login-logout {
    font-weight: normal;
}
.LogoAndUser .user-details img {
    height: 40px;
    border-radius: 50%;
    margin: 5px 0 5px 10px;
}
.LogoAndUser .user-details div {
    text-align: right;
}

.LogoAndUser .logo {
    background-color: var(--dark-shade);
    margin: 5px 0 5px 10px;
    padding: 5px;
    border-radius: 50px;
    max-height: 60px;
}

.LogoAndUser .logo img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    margin: 5px 5px 5px 5px;
}

.LogoAndUser .logo .company-name {
    float: right;
    display: none;
    padding-top: 12px;
    padding-right: 5px;
    font-size: larger;
    font-weight: bold;
    color: var(--light-shade);
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.Nav {
    background-color: var(--dark-shade);
    top: 0;
    position: sticky;
}
.Nav ul {
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    align-items: stretch;
}
.Nav ul li {
    flex-grow: 1;
    border-left: 2px solid var(--brand-color);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}

.Nav ul li:first-child {
    border-left: none;
}
.Nav ul li:hover {
    background-color: var(--brand-color);
}
.Nav ul li a {
    display: flex;
    justify-self: center;
    align-self: center;
    font-weight: bold;
    align-items: stretch;
    color: var(--light-shade);
    text-decoration: none;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.Home {
    display: flex;
    flex-direction: column;
}
.Home a {
    color: var(--dark-accent);
    font-weight: bold;
    text-decoration: underline;
}
.Home .welcome {
    flex-grow: 1;
    height: 300px;
    background-image: url("https://images.pexels.com/photos/6150432/pexels-photo-6150432.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}
.Home .welcome section {
    background-color: rgba(245, 245, 235, 0.8);
    text-align: center;
    font-weight: bold;
    width: 100%;
}
.Home .welcome section > p {
    margin-top: 10px;
    margin-bottom: 15px;
}
.Home .get-started {
    font-size: 1.4em;
}
.Home .get-started h2 {
    text-align: center;
}
.Home .get-started > div {
    background-color: var(--light-accent);
    border-radius: 5px;
    padding: 10px;
}
.Home .get-started ul {
    margin-left: 25px;
}
.Home .explanation {
    background-color: var(--brand-color);
}
.Home .explanation > p {
    margin-bottom: 10px;
}
.playlist-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.playlist-info .review-data,
p {
    align-self: flex-start;
    min-height: 20px;
}
.playlist-info h2 {
    align-self: flex-start;
    min-height: 90px;
}
.playlist-info a:hover {
    font-weight: bold;
}
.playlist-info .delete-button {
    padding: 8px 2px;
}
.playlist-info .delete-btn {
    background-color: var(--dark-accent);
    border: none;
    color: var(--light-shade);
    padding: 8px 16px;
    font-size: 16px;
    transition-duration: 0.4s;
}
.playlist-info .delete-btn:hover,
.delete-btn:active {
    background-color: var(--brand-color);
    font-weight: normal;
}
.Playlists .review-votestars button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 30px;
}

.Playlists .review-votestars .on {
    text-shadow: 1px 1px 2px yellow, 0 0 2em var(--brand-color), 0 0 0.2em blue;
    color: yellow;
}
.Playlists .review-votestars .off {
    color: #f5f5eb;
}

.Playlists ul {
    list-style-type: none;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 100%);
    padding-left: 0px;
    justify-content: center;
}
.Playlists ul li {
    background-color: var(--light-accent);
    transition-delay: 0.1s;
}
.Playlists ul li:hover {
    background-color: var(--dark-shade);
}
.Playlists ul li img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    justify-self: center;
}
.Playlists ul li a {
    color: var(--dark-accent);
    text-decoration: none;
}

.Playlists .review-data p {
    display: inline-block;
}

.Playlist {
    margin: 10px 0;
}
.Playlist .playlist-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--dark-accent);
}
.Playlist .playlist-description {
    font-size: 1.2em;
    font-weight: bold;
    padding: 10px;
    color: var(--light-shade);
    background-color: var(--dark-accent);
}
.Playlist ul {
    background-color: var(--light-accent);
    padding: 10px;
    list-style-type: none;
    margin: 10px 0;
    border-radius: 10px;
}
.Playlist ul li {
    border-top: 2px solid var(--dark-accent);
    padding: 15px 0;
}
.Playlist ul li:first-child {
    border-top: none;
    padding-top: 0;
}
.Playlist ul li:last-child {
    padding-bottom: 0;
}
.Playlist ul li .restaurant-image {
    border: 2px solid var(--dark-accent);
    border-bottom: none;
    height: 150px;
    width: 100%;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    display: block;
}
.Playlist ul li .restaurant-details {
    border: 2px solid var(--dark-accent);
    border-top: none;
    background-color: var(--light-shade);
    border-radius: 0 0 10px 10px;
    padding: 10px;
}
.Playlist .cuisine-img {
    object-fit: cover;
    width: 100%;
    height: 150px;
    margin-top: 10px;
    display: block;
}
.Playlist h3 > a {
    float: right;
    text-decoration: none;
    color: var(--dark-shade);
    font-size: 0.8em;
    font-weight: normal;
}
.Playlist .delete-restaurant-btn {
    background-color: var(--dark-accent);
    text-align: center;
    margin-top: 10px;
    border-radius: 3px;
}
.Playlist .delete-restaurant-btn a {
    display: block;
    color: var(--light-accent);
    padding: 5px;
}

.profile-details img {
    border-radius: 50%;
}

.CreatePlaylist {
    display: flex;
    flex-direction: column;
}
.CreatePlaylist label {
    display: flex;
    flex-direction: column;
}
.CreatePlaylist input {
    padding: 10px;
    font-size: 1.3em;
}

.CreatePlaylist button {
    background-color: var(--light-accent);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
}
.CreatePlaylist button.playlist-submit {
    background-color: var(--dark-shade);
    text-transform: uppercase;
}
.CreatePlaylist button:hover {
    background-color: var(--dark-accent);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.CreatePlaylist .form-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 5px;
}

.CreatePlaylist p {
    min-height: 20px;
    align-self: center;
    animation: hideAnimation 0s ease-in 5s;
    animation-fill-mode: forwards;
}

.CreatePlaylist label {
    padding-bottom: 5px;
    margin: 5px;
}

.Footer {
    background-color: var(--dark-shade);
    color: var(--light-shade);
}

.Footer a {
    font-weight: bold;
    color: var(--light-shade);
}

/* component padding and margins */

.Playlist {
    margin-left: 10px;
    margin-right: 10px;
}

.Playlists ul li .playlist-info,
.Nav ul li a,
.Home .welcome section,
.Home .get-started,
.Home .explanation,
.Playlists,
.Profile,
.profile-details,
.CreatePlaylistPage,
.Footer {
    padding: 10px;
}

.Home,
.Profile,
.Playlists,
.Playlist {
    flex-grow: 1;
}

@media only screen and (min-width: 400px) {
    /* This is only applicable for playlist thumbnail */
    .Playlists ul li img {
        height: 150px;
    }

    .LogoAndUser .logo .company-name {
        float: right;
        display: flex;
    }
}

@media only screen and (min-width: 520px) {
    /* This is only applicable for playlist thumbnail */
    .Playlists ul {
        grid-template-columns: repeat(auto-fill, 45%);
    }
    .Playlists ul li img {
        height: 120px;
    }
    .Home,
    .CreatePlaylist {
        margin: 0% 5% 0% 5%;
    }
}

@media only screen and (min-width: 700px) {
    /* This is only applicable for playlist thumbnail */
    .Playlists ul {
        grid-template-columns: repeat(auto-fill, 30%);
    }
    .Playlists ul li img {
        height: 150px;
    }

    .Home .get-started > div {
        width: 65%;
        margin: 0 auto;
    }

    .Nav ul li a {
        display: inline;
        width: 100%;
        height: 100%;
    }

    .Playlist {
        max-width: 900px;
        margin: 0 auto;
        padding: 10px;
    }
    .Playlist .cuisine-img {
        display: none;
    }
    .Playlist .playlist-description {
        margin-top: 10px;
        padding: 15px;
    }
    .Playlist ul li {
        display: flex;
    }
    .Playlist ul li .restaurant-image {
        border: 2px solid var(--dark-accent);
        border-right: none;
        width: 250px;
        border-radius: 10px 0 0 10px;
    }
    .Playlist ul li .restaurant-details {
        border: 2px solid var(--dark-accent);
        border-left: none;
        border-radius: 0 10px 10px 0;
        flex-grow: 1;
    }

    .Home,
    .CreatePlaylist {
        margin: 0% 10% 0% 10%;
    }
}

@media only screen and (min-width: 1200px) {
    /* This is only applicable for playlist thumbnail */
    .Playlists ul {
        grid-template-columns: repeat(auto-fill, 24%);
    }
    .Playlists ul li img {
        height: 200px;
    }

    .Home .get-started > div {
        width: 55%;
    }

    .Home,
    .CreatePlaylist {
        margin: 0% 15% 0% 15%;
    }
}
